<template>
  <div>
    <header1Component
      :circleActive="0"
      :detailActive="0"
      :loopCircleSmall="3"
      :circleSmall="1"
      titleHeader="หัวข้อ : 1.ข้อมูลขอรับการส่งเสริมและสนับสนุนเงินจากกองทุน"
    />
    <div class="container">
      <div class="card2">
        <div class="title conditionCard2" align="center">หัวหน้าโครงการ</div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            ชื่อ <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="ชื่อ"
              v-model="projectLeader.firstName"
            />
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            นามสกุล <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="นามสกุล"
              v-model="projectLeader.lastName"
            />
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            เลขประจำตัวประชาชน <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="เลขประจำตัวประชาชน"
              v-model="projectLeader.cardNumber"
            />
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            ตำแหน่งในโครงการ <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="ตำแหน่งในโครงการ"
              v-model="projectLeader.projectPosition"
            />
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            อีเมล <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="อีเมล"
              v-model="projectLeader.email"
            />
          </div>
        </div>

        <div class="flex flex-wrap" style="padding-left: 62px">
          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                โทรศัพท์ <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="โทรศัพท์"
                  v-model="projectLeader.phone"
                />
              </div>
            </div>
          </div>

          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                ต่อ <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="โทรศัพท์"
                  v-model="projectLeader.phoneExtention"
                />
              </div>
            </div>
          </div>

          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                โทรสาร <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="โทรสาร"
                  v-model="projectLeader.fax"
                />
              </div>
            </div>
          </div>

          <div class="w-1/2"></div>

          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                โทรศัพท์มือถือ <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="โทรศัพท์มือถือ"
                  v-model="projectLeader.mobilePhone"
                />
              </div>
            </div>
          </div>
        </div>

        <div align="center" style="padding-top: 30px; padding-bottom: 10px">
          <input
            type="file"
            @change="uploadImage1($event)"
            id="uploadSingle1"
            hidden
          />
          <label for="uploadSingle1"
            >แนบประวัติบุคลากรในโครงการ (pdf) <font color="red">*</font></label
          >
        </div>

        <hr />

        <div class="title conditionCard2" align="center">ผู้ร่วมงานหลัก</div>

        <div v-for="(item, index) in mainParticipant" :key="index">
          <div class="flex flex-row" style="margin-bottom: 10px">
            <div
              class="contentActive flex items-center justify-end"
              align="right"
            >
              ชื่อ <font color="red">*</font>
            </div>
            <div class="w-full" style="margin-right: 73px">
              <input
                type="text"
                placeholder="ชื่อ"
                v-model="mainParticipant[index].firstName"
              />
            </div>
          </div>

          <div class="flex flex-row" style="margin-bottom: 10px">
            <div
              class="contentActive flex items-center justify-end"
              align="right"
            >
              นามสกุล <font color="red">*</font>
            </div>
            <div class="w-full" style="margin-right: 73px">
              <input
                type="text"
                placeholder="นามสกุล"
                v-model="mainParticipant[index].lastName"
              />
            </div>
          </div>

          <div class="flex flex-row" style="margin-bottom: 10px">
            <div
              class="contentActive flex items-center justify-end"
              align="right"
            >
              เลขประจำตัวประชาชน <font color="red">*</font>
            </div>
            <div class="w-full" style="margin-right: 73px">
              <input
                type="text"
                placeholder="เลขประจำตัวประชาชน"
                v-model="mainParticipant[index].cardNumber"
              />
            </div>
          </div>

          <div class="flex flex-row" style="margin-bottom: 10px">
            <div
              class="contentActive flex items-center justify-end"
              align="right"
            >
              ตำแหน่งในโครงการ <font color="red">*</font>
            </div>
            <div class="w-full" style="margin-right: 73px">
              <input
                type="text"
                placeholder="ตำแหน่งในโครงการ"
                v-model="mainParticipant[index].projectPosition"
              />
            </div>
          </div>

          <div class="flex flex-row" style="margin-bottom: 10px">
            <div
              class="contentActive flex items-center justify-end"
              align="right"
            >
              อีเมล <font color="red">*</font>
            </div>
            <div class="w-full" style="margin-right: 73px">
              <input
                type="text"
                placeholder="อีเมล"
                v-model="mainParticipant[index].email"
              />
            </div>
          </div>

          <div class="flex flex-wrap" style="padding-left: 62px">
            <div class="w-1/2">
              <div class="flex flex-row" style="margin-bottom: 10px">
                <div
                  class="contentActive flex items-center justify-end"
                  align="right"
                >
                  โทรศัพท์ <font color="red">*</font>
                </div>
                <div class="w-full" style="margin-right: 73px">
                  <input
                    type="text"
                    placeholder="โทรศัพท์"
                    v-model="mainParticipant[index].phone"
                  />
                </div>
              </div>
            </div>

            <div class="w-1/2">
              <div class="flex flex-row" style="margin-bottom: 10px">
                <div
                  class="contentActive flex items-center justify-end"
                  align="right"
                >
                  ต่อ <font color="red">*</font>
                </div>
                <div class="w-full" style="margin-right: 73px">
                  <input
                    type="text"
                    placeholder="โทรศัพท์"
                    v-model="mainParticipant[index].phoneExtention"
                  />
                </div>
              </div>
            </div>

            <div class="w-1/2">
              <div class="flex flex-row" style="margin-bottom: 10px">
                <div
                  class="contentActive flex items-center justify-end"
                  align="right"
                >
                  โทรสาร <font color="red">*</font>
                </div>
                <div class="w-full" style="margin-right: 73px">
                  <input
                    type="text"
                    placeholder="โทรสาร"
                    v-model="mainParticipant[index].fax"
                  />
                </div>
              </div>
            </div>

            <div class="w-1/2"></div>

            <div class="w-1/2">
              <div class="flex flex-row" style="margin-bottom: 10px">
                <div
                  class="contentActive flex items-center justify-end"
                  align="right"
                >
                  โทรศัพท์มือถือ <font color="red">*</font>
                </div>
                <div class="w-full" style="margin-right: 73px">
                  <input
                    type="text"
                    placeholder="โทรศัพท์มือถือ"
                    v-model="mainParticipant[index].mobilePhone"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            align="center"
            :style="`padding-top: 30px; padding-bottom: 10px; ${
              index > 0 ? 'margin-bottom: 30px;' : ''
            }`"
          >
            <input
              type="file"
              :id="`upload${index}`"
              hidden
              @change="uploadImage2($event, index)"
            />
            <label :for="`upload${index}`"
              >แนบประวัติบุคลากรในโครงการ (pdf)
              <font color="red">*</font></label
            >
          </div>

          <div
            v-if="index === 0"
            class="
              flex flex-row
              items-center
              justify-center justify-items-center
            "
            align="center"
          >
            <div
              class="
                circle
                flex
                items-center
                justify-center justify-items-center
              "
              style="margin-right: 16px"
              @click="addFormMainParticipant()"
            >
              <font-awesome-icon
                style="width: 18px; height: 23px"
                icon="plus"
                color="white"
              />
            </div>

            <div class="titleCircle flex items-center">เพิ่มผู้ร่วมงานหลัก</div>
          </div>

          <div
            v-else
            class="
              flex flex-row
              items-center
              justify-center justify-items-center
            "
            align="center"
          >
            <div
              class="
                circle
                flex
                items-center
                justify-center justify-items-center
              "
              style="margin-right: 16px; background-color: red"
              @click="deleteFormMainParticipant(index)"
            >
              <font-awesome-icon
                style="width: 18px; height: 23px"
                icon="minus"
                color="white"
              />
            </div>

            <div class="titleCircle flex items-center">ลบผู้ร่วมงานหลัก</div>
          </div>
        </div>

        <hr />

        <div class="title conditionCard2" align="center">ผู้ประสานงาน</div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            ชื่อ <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="ชื่อ"
              v-model="coordinator.firstName"
            />
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            นามสกุล <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="นามสกุล"
              v-model="coordinator.lastName"
            />
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            เลขประจำตัวประชาชน <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="เลขประจำตัวประชาชน"
              v-model="coordinator.cardNumber"
            />
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            ตำแหน่งในโครงการ <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="ตำแหน่งในโครงการ"
              v-model="coordinator.projectPosition"
            />
          </div>
        </div>

        <div class="flex flex-row" style="margin-bottom: 10px">
          <div
            class="contentActive flex items-center justify-end"
            align="right"
          >
            อีเมล <font color="red">*</font>
          </div>
          <div class="w-full" style="margin-right: 73px">
            <input
              type="text"
              placeholder="อีเมล"
              v-model="coordinator.email"
            />
          </div>
        </div>

        <div class="flex flex-wrap" style="padding-left: 62px">
          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                โทรศัพท์ <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="โทรศัพท์"
                  v-model="coordinator.phone"
                />
              </div>
            </div>
          </div>

          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                ต่อ <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="โทรศัพท์"
                  v-model="coordinator.phoneExtention"
                />
              </div>
            </div>
          </div>

          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                โทรสาร <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="โทรสาร"
                  v-model="coordinator.fax"
                />
              </div>
            </div>
          </div>

          <div class="w-1/2"></div>

          <div class="w-1/2">
            <div class="flex flex-row" style="margin-bottom: 10px">
              <div
                class="contentActive flex items-center justify-end"
                align="right"
              >
                โทรศัพท์มือถือ <font color="red">*</font>
              </div>
              <div class="w-full" style="margin-right: 73px">
                <input
                  type="text"
                  placeholder="โทรศัพท์มือถือ"
                  v-model="coordinator.mobilePhone"
                />
              </div>
            </div>
          </div>
        </div>

        <div align="center" style="padding-top: 30px; padding-bottom: 10px">
          <input
            type="file"
            id="uploadSingle3"
            hidden
            @change="uploadImage3($event)"
          />
          <label for="uploadSingle3"
            >แนบประวัติบุคลากรในโครงการ (pdf) <font color="red">*</font></label
          >
        </div>

        <hr />

        <div class="flex flex-row">
          <div
            class="w-1/2 flex flex-start justify-start"
            style="margin-left: 72px; margin-top: 81px"
          >
            <button class="buttonPrevious" @click="createRequest()">
              ก่อนหน้า
            </button>
          </div>

          <div
            class="w-1/2 flex items-end justify-end"
            style="margin-right: 72px; margin-top: 81px"
          >
            <button class="buttonNext" @click="updateRequest()">ถัดไป</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useRequest from "../../hooks/useRequest";
import useUpload from "../../hooks/useUpload";
import header1Component from "@/components/capital/header1Component";
export default {
  components: {
    header1Component,
  },
  mounted() {
    this.getData();
  },
  methods: {
    uploadImage1(event) {
      this.projectLeader.fileProjectpersonnelHistory = event.target.files[0];
      console.log(this.projectLeader.fileProjectpersonnelHistory);
    },
    uploadImage2(event, index) {
      this.mainParticipant[index].fileProjectpersonnelHistory =
        event.target.files[0];
    },
    uploadImage3(event) {
      this.coordinator.fileProjectpersonnelHistory = event.target.files[0];
      console.log(this.coordinator.fileProjectpersonnelHistory);
    },
    addFormMainParticipant() {
      this.mainParticipant.push({
        firstName: null,
        lastName: null,
        cardNumber: null,
        projectPosition: null,
        email: null,
        phone: null,
        phoneExtention: null,
        fax: null,
        mobilePhone: null,
        fileProjectpersonnelHistory: null,
      });
    },
    deleteFormMainParticipant(id) {
      this.mainParticipant.splice(id, 1);
    },

    getData() {
      useRequest.getProjectId(this.id).then(response => {
        this.projectLeader = response.data.projectLeader;
        this.mainParticipant = response.data.mainParticipant;
        this.coordinator = response.data.coordinator;
      });
    },

    async updateRequest() {
      let data1 = new FormData();
      data1.append(
        "fileUpload",
        this.projectLeader.fileProjectpersonnelHistory
      );
      await useUpload.uploadFile(data1).then((response) => {
        if (response.data.file !== '') {
          this.projectLeader.fileProjectpersonnelHistory = response.data.file;
          console.log(this.projectLeader.fileProjectpersonnelHistory);
        }
      });

      useRequest
        .updateProject(this.id, {projectLeader: this.projectLeader})
        .then((response) => {
          console.log(response.data);
        });

      for (let i = 0; i !== this.mainParticipant.length; i++) {
        let data2 = new FormData();
        data2.append(
          "fileUpload",
          this.mainParticipant[i].fileProjectpersonnelHistory
        );
        await useUpload.uploadFile(data2).then((response) => {
          if (response.data.file !== '') {
            this.mainParticipant[i].fileProjectpersonnelHistory =
            response.data.file;
          }
        });
      }

      useRequest
        .updateProject(this.id, {mainParticipant: this.mainParticipant})
        .then((response) => {
          console.log(response.data);
        });

      let data3 = new FormData();
      data3.append("fileUpload", this.coordinator.fileProjectpersonnelHistory);
      await useUpload.uploadFile(data3).then((response) => {
        if (response.data.file !== '') {
          this.coordinator.fileProjectpersonnelHistory = response.data.file;
        }
      });

      useRequest
        .updateProject(this.id, {coordinator: this.coordinator})
        .then((response) => {
          console.log(response.data);
        });

      useRequest
        .updateProject(this.id, {pageStatus: 2})
        .then((response) => {
          console.log(response.data);
        });
    },
  },
  setup() {
    const projectLeader = ref({
      firstName: null,
      lastName: null,
      cardNumber: null,
      projectPosition: null,
      email: null,
      phone: null,
      phoneExtention: null,
      fax: null,
      mobilePhone: null,
      fileProjectpersonnelHistory: null,
    });

    const mainParticipant = ref([
      {
        firstName: null,
        lastName: null,
        cardNumber: null,
        projectPosition: null,
        email: null,
        phone: null,
        phoneExtention: null,
        fax: null,
        mobilePhone: null,
        fileProjectpersonnelHistory: null,
      },
    ]);

    const coordinator = ref({
      firstName: null,
      lastName: null,
      cardNumber: null,
      projectPosition: null,
      email: null,
      phone: null,
      phoneExtention: null,
      fax: null,
      mobilePhone: null,
      fileProjectpersonnelHistory: null,
    });

    return { projectLeader, mainParticipant, coordinator };
  },
  data() {
    return {
      id: this.$route.params.id,
    };
  },
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.card2 {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  padding-top: 34px;
  padding-bottom: 36px;
  margin-bottom: 36px;
}

.conditionCard2 {
  margin-left: 73px;
  margin-right: 73px;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;

  color: #242424;
  margin-bottom: 10px;
}

hr {
  margin-left: 33px;
  margin-right: 33px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #c4c4c4;
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #575757;
}

.contentActive {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
  width: 300px;
  margin-right: 24px;
  margin-left: 48px;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

label {
  width: 420px;
  height: 51px;
  color: white;
  background: #573c81;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 57px;
  padding-right: 57px;
}

.buttonPrevious {
  width: 195px;
  height: 50px;
  color: #573c81;
  background: #ffffff;
  border: 1px solid #573c81;
  box-sizing: border-box;
  border-radius: 5px;
}

.buttonNext {
  width: 195px;
  height: 50px;
  color: white;
  background: #573c81;
  border-radius: 5px;
}

.circle {
  width: 40px;
  height: 40px;
  color: white;
  background: #573c81;
  border-radius: 100%;
  margin-top: 36px;
  margin-bottom: 36px;
  font-size: 36px;
}

.titleCircle {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
}
</style>
